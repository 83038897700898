var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'',"arrayExcel":[],"showBtnMultiDelete":false,"hideAdd":false,"hideDelete":false,"hideDowload":false,"hideExportFile":false,"hideImportFile":false},on:{"clickDelete":function (){},"clickDowloadSample":function (){},"clickExportExcel":function (){},"importFile":function (){},"clickAdd":function (){},"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'dateSelfClare')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateSelfClare))+" ")]):(props.column.field === 'ActionFunction')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.showModalSeePdf(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem file'),expression:"'Xem file'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('ModelSeePdf',{attrs:{"id":_vm.modaIdSee,"pdfsrc":_vm.pdfsrc},on:{"downloadFilePdf":_vm.downloadFilePdf}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }