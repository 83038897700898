<template>
  <div
    id="users"
    class="page-container-table"
  >
    <button-all-header
      :contentBtnAdd="''"
      :arrayExcel="[]"
      :showBtnMultiDelete="false"
      :hideAdd="false"
      :hideDelete="false"
      :hideDowload="false"
      :hideExportFile="false"
      :hideImportFile="false"
      @clickDelete="()=> {}"
      @clickDowloadSample="()=> {}"
      @clickExportExcel="()=> {}"
      @importFile="()=> {}"
      @clickAdd="()=> {}"
      @search="search($event)"
    />
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->
        <span v-if="props.column.field === 'dateSelfClare'">
          {{ props.row.dateSelfClare |formatDateToDDMM }}
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            class="ml-2"
            @click="showModalSeePdf(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xem file'"
              icon="EyeIcon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <ModelSeePdf
      :id="modaIdSee"
      :pdfsrc="pdfsrc"
      @downloadFilePdf="downloadFilePdf"
    />

  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import {
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ModelSeePdf from './components/ModalSeePdf.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalSeePDF from '@/views/employment-service/report-service/pages/components/ModalSeePDF.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    FeatherIcon,
    ButtonAllHeader,
    ModelSeePdf,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      columns: [
        {
          label: 'DOANH  NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'NGÀY CÔNG BỐ',
          field: 'dateSelfClare',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      modaIdSee: 'modal-seed-pdf-condition',
      dataList: [],
      pdfsrc: '',
      idDocumentTraining: '',
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    showModalSeePdf(val) {
      this.idDocumentTraining = val
      axiosApiInstance({
        url: ConstantsApi.FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId: val },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modaIdSee)
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_LIST_DECLARE_TRAINING, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    downloadFilePdf() {
      axiosApiInstance({
        url: ConstantsApi.FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId: this.idDocumentTraining },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'GiayThongBaoDuDieuKienHuanLuyenHangA.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
